import { useRef, memo } from 'react';
import { useRouter } from 'next/router';
import { MasonryScroller, usePositioner, useContainerPosition, useResizeObserver } from 'masonic';
import Link from 'next/link';
import sortBy from 'lodash/sortBy';
import { getCategoryTitle, bannedSubCatalogs, generateUrl } from '@src/utils';
import { useWindowSize } from '@src/utils/hooks';
import * as Styles from './submenu.styled';

const SubMenu = ({ layout, showed, subs, handleSubmenu }) => {
    const { locale } = useRouter();
    const containerRef = useRef(null);
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);
    const positioner = usePositioner({ width, columnWidth: 280 });
    const resizeObserver = useResizeObserver(positioner);

    const submenu = sortBy(
        layout.submenu.find((s) => s.id === process.env.NEXT_PUBLIC_ALL_PRODUCTS_ID)?.sub_segments || [],
        [(segment) => getCategoryTitle(segment, locale, true)],
    );

    /**
     * This function call the handleSubmenu function from Menu component to hide the menu
     */
    function handleFromMenu() {
        handleSubmenu(false);
    }

    return (
        <Styles.Wrapper id="sub_menu" className={showed ? '__showed' : ''}>
            <Styles.Nav>
                <MasonryScroller
                    key={locale}
                    positioner={positioner}
                    resizeObserver={resizeObserver}
                    offset={offset}
                    height={windowHeight}
                    containerRef={containerRef}
                    overscanBy={6}
                    itemStyle={{ paddingBottom: '13px' }}
                    itemKey={(data) => data.id + locale}
                    items={submenu}
                    render={({ data: segment }) => {
                        /**
                         *  @todo Dynamize in future
                         *  @key SUBSEGMENT_PF
                         */
                        const tempoSubs = segment[segment.id === '63fac81e-9be8-4602-9879-e34a26164a61' ? 'sub_segments' : subs];

                        if (tempoSubs.length === 0) {
                            return null;
                        }

                        const resellerProduct = sortBy(tempoSubs, [(seg) => getCategoryTitle(seg, locale)]);

                        return (
                            <li key={segment.id}>
                                <Link href={generateUrl({ levelOne: segment, locale })} passHref prefetch={false}>
                                    <Styles.Category className="segment_link" onClick={handleFromMenu}>{getCategoryTitle(segment, locale)}</Styles.Category>
                                </Link>

                                <ul>
                                    {resellerProduct.map((subCatalog) => {
                                        const title = getCategoryTitle(subCatalog, locale);

                                        if (title === null || bannedSubCatalogs.includes(subCatalog.id)) {
                                            return null;
                                        }

                                        return (
                                            <li key={subCatalog.id}>
                                                <Link href={generateUrl({ levelOne: segment, levelTwo: subCatalog, locale })} passHref prefetch={false}>
                                                    <Styles.SubCategory className="productGroup_link" onClick={handleFromMenu}>{title}</Styles.SubCategory>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        );
                    }}
                />
            </Styles.Nav>
        </Styles.Wrapper>
    );
};

export default memo(SubMenu);
