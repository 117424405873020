/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { em } from 'polished';

const ExtendLink = css`
    font-size: ${em('14px')};
    cursor: pointer;
    font-weight: 400;
    display: block;
    padding: 3px 0;
    text-align: left;
    color: var(--light);

    &:focus {
        outline: 0;
    }
`;

export const Wrapper = styled.nav`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    z-index: -1;
    margin-top: 39px;

    &.__showed {
        visibility: visible;
        z-index: 2;
    }
`;

export const Nav = styled.ul`
    position: relative;
    background-color: #fff;
    padding: 1em;
    box-shadow: 0 2px 18px 0 rgba(162,204,244,0.5);
    z-index: 3;
    max-width: 1280px;
    text-align: left;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;

    > li {
        position: relative;
    }
`;

export const Category = styled.a`
    background: transparent;
    border: 0;
    ${ExtendLink}
    font-weight: 700;
    display: inline-block;
    color: var(--dark);

    &:hover, &.__clicked {
        font-weight: 700;
        color: var(--primary);
    }
`;

export const Sub = styled.ul`
    border-radius: 0 5px 5px 0;
    background-color: #FFF;
    box-shadow: 0 2px 18px 0 rgba(162,204,244,0.5);
    padding: 1em;
    position: absolute;
    left: 100%;
    visibility: hidden;
    width: ${(props) => {
        if (props.count > 20 && props.count <= 42) {
            return '500px';
        }

        if (props.count > 42) {
            return '650px';
        }

        return '100%';
    }};
    z-index: 2;

    > li {
        display: inline-block;
        vertical-align: top;
        margin-right: 5px;
        width: ${(props) => {
            if (props.count > 20 && props.count <= 42) {
                return '48.5%';
            }

            if (props.count > 42) {
                return '32.3333%';
            }

            return '100%';
        }};

        &:first-of-type {
            width: 100%;

            > a {
                font-weight: 700;
                color: var(--normal);

                &:hover {
                    font-weight: 700;
                    color: var(--primary);
                }
            }
        }
    }
`;

export const SubCategory = styled.a`
    ${ExtendLink}

    &:hover {
        color: var(--primary);
    }
`;
