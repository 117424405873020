import { connect } from 'react-redux';
import SubMenu from './submenu';

const mapStateToProps = ({ layout, catalog }) => ({
    layout,
    catalog,
    subs: process.env.NEXT_PUBLIC_SHOP_NAME === 'office' ? 'sub_segments' : 'products_groups',
});

export default connect(mapStateToProps, null)(SubMenu);
